import Section from '@/components/ui/atoms/Section'
import {
  Facebook,
  Instagram,
  Linkedin,
  Twitter,
  Youtube,
} from '@/components/ui/atoms/brand'
import { cn } from '@/lib/utils'
import type { INavigationItem } from '@/types/navigation'
import { useStore } from '@nanostores/react'
import { ChevronDown, ChevronUp } from 'lucide-react'
import { atom } from 'nanostores'
import { Fragment } from 'react'

const seeMoreAtom = atom<boolean>(false)

export default function FooterEnhanced({
  navigation,
}: { navigation: INavigationItem[] }) {
  const $seeMore = useStore(seeMoreAtom)

  return (
    <footer>
      <Section id="footer">
        <>
          <div
            className={cn(
              'grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-x-4 gap-y-12 text-xs text-gray-500',
              !$seeMore && 'overflow-hidden max-h-[600px]',
            )}
          >
            <div>
              <img
                loading="lazy"
                src="/images/logo-color.webp"
                height={43}
                width={170}
                alt="Direção Concursos - Rodapé"
              />
              <p className="mt-4 text-xs leading-5 font-medium max-w-[250px] text-zinc-400">
                Direção Concursos representa, na atualidade, o que há de melhor
                na preparação em alto rendimento para concursos públicos no
                Brasil.
              </p>
            </div>
            {navigation.map((link) => {
              return (
                <div key={link.id}>
                  <h2 className="font-medium text-base text-orange-600">
                    {link.title}
                  </h2>
                  <ul
                    role="list"
                    aria-labelledby={link.title}
                    className="mt-6 space-y-6 sm:mt-4 sm:space-y-4"
                  >
                    {link?.children?.map((child) => {
                      return (
                        <Fragment key={child.id}>
                          <li
                            className={cn(
                              'flex',
                              child.children?.length &&
                                'font-medium text-gray-900',
                            )}
                            key={child.id}
                          >
                            <a
                              className="hover:text-gray-800"
                              href={child.link}
                            >
                              {child.title}
                            </a>
                          </li>
                          {child.children?.map(({ id, link, title }) => (
                            <li className={cn('flex')} key={id}>
                              <a className="hover:text-gray-800" href={link}>
                                {title}
                              </a>
                            </li>
                          ))}
                        </Fragment>
                      )
                    })}
                  </ul>
                </div>
              )
            })}
          </div>
          <div
            className={cn(
              'bg-gradient-to-t from-white relative min-h-[150px] -mt-[150px] z-20',
              $seeMore && 'hidden',
            )}
          />
          <button
            type="button"
            onClick={() => seeMoreAtom.set(!$seeMore)}
            className="flex w-full justify-center items-center px-8 py-2 text-xs text-orange-500 font-medium"
          >
            {$seeMore ? (
              <>
                Ver menos
                <ChevronUp
                  className="relative top-[1px] ml-1 h-3 w-3 transition duration-200 group-data-[state=open]:rotate-180"
                  aria-hidden="true"
                />
              </>
            ) : (
              <>
                Ver mais
                <ChevronDown
                  className="relative top-[1px] ml-1 h-3 w-3 transition duration-200 group-data-[state=open]:rotate-180"
                  aria-hidden="true"
                />
              </>
            )}
          </button>
        </>
      </Section>
      <Section
        id="footer-payment-and-social-media"
        className="border-t py-5 sm:py-5 lg:py-5"
      >
        <div className="grid grid-cols-1 lg:grid-cols-3 items-center justify-between">
          <div className="grid col-span-full lg:col-span-2 items-center">
            <div className="flex flex-wrap gap-2 items-center justify-center lg:justify-start">
              <p className="text-xs my-4 text-zinc-600 basis-full text-center lg:basis-auto">
                Formas de pagamento
              </p>
              <div className="px-3 py-2 border rounded-sm">
                <img
                  loading="lazy"
                  src="/images/visa-logo.webp"
                  height={26}
                  width={38}
                  alt="Logo do Direção Concursos"
                />
              </div>
              <div className="px-3 py-2 bg-[#1f72cd] border rounded-sm">
                <img
                  loading="lazy"
                  src="/images/american-express-logo.webp"
                  height={26}
                  width={38}
                  aria-label="Amex"
                  alt="Logo do Direção Concursos"
                />
              </div>
              <div className="px-3 py-2 border rounded-sm">
                <img
                  loading="lazy"
                  src="/images/mastercard-logo.webp"
                  height={26}
                  width={38}
                  alt="Logo do Direção Concursos"
                />
              </div>
              <div className="px-3 py-2 border rounded-sm">
                <img
                  loading="lazy"
                  src="/images/hipercard-logo.webp"
                  height={26}
                  width={38}
                  alt="Logo do Direção Concursos"
                />
              </div>
              <div className="px-3 py-2 border rounded-sm">
                <img
                  loading="lazy"
                  src="/images/elo-logo.webp"
                  height={26}
                  width={38}
                  alt="Logo do Direção Concursos"
                />
              </div>
              <div className="px-3 py-2 border rounded-sm">
                <img
                  loading="lazy"
                  src="/images/boleto-logo.webp"
                  height={26}
                  width={38}
                  alt="Logo do Direção Concursos"
                />
              </div>
              <div className="px-3 py-2 border rounded-sm">
                <img
                  loading="lazy"
                  src="/images/pix-logo.webp"
                  height={26}
                  width={38}
                  alt="Logo do Direção Concursos"
                />
              </div>
            </div>
          </div>
          <div className="flex my-4 items-end justify-center lg:justify-end gap-3">
            <a
              className="text-white bg-sky-700 p-2 rounded-sm"
              target="_blank"
              rel="noreferrer"
              aria-label="LinkedIn"
              href="https://www.linkedin.com/company/direcao-concursos/mycompany/"
            >
              <Linkedin size={16} />
            </a>
            <a
              className="text-white bg-sky-700 p-2 rounded-sm"
              target="_blank"
              rel="noreferrer"
              aria-label="Instagram"
              href="https://www.instagram.com/direcaoconcursos/"
            >
              <Instagram size={16} />
            </a>
            <a
              className="text-white bg-sky-700 p-2 rounded-sm"
              target="_blank"
              rel="noreferrer"
              aria-label="Facebook"
              href="https://www.facebook.com/direcaoconcursos/"
            >
              <Facebook size={16} />
            </a>
            <a
              className="text-white bg-sky-700 p-2 rounded-sm"
              target="_blank"
              rel="noreferrer"
              aria-label="Twitter"
              href="https://twitter.com/direcaoconcurso"
            >
              <Twitter size={16} />
            </a>
            <a
              className="text-white bg-sky-700 p-2 rounded-sm"
              target="_blank"
              rel="noreferrer"
              aria-label="Youtube"
              href="https://www.youtube.com/c/Dire%C3%A7%C3%A3oConcursos"
            >
              <Youtube size={16} />
            </a>
          </div>
        </div>
      </Section>
      <section className="w-full mt-0 mx-auto text-center bg-zinc-800 text-white">
        <p className="text-[.65rem] p-6">
          DIREÇÃO CONCURSOS - CURSOS ONLINE PARA CONCURSOS. TODOS OS DIREITOS
          RESERVADOS. CNPJ: 32.161.525/0001-03
        </p>
      </section>
    </footer>
  )
}
