import type { INavigationItem } from '@/types/navigation'
import HeaderCheckout from './header-checkout'
import HeaderEnhanced from './header-enhanced'
import HeaderInline from './header-inline'

export default function Header({
  type = 'enhanced',
  navigation,
}: {
  type: 'inline' | 'enhanced' | 'checkout' | 'checkout-contrast'
  navigation: INavigationItem[]
}) {
  if (type === 'enhanced') {
    return <HeaderEnhanced navigation={navigation} />
  }

  if (type === 'checkout' || type === 'checkout-contrast') {
    return <HeaderCheckout contrast={type === 'checkout-contrast'} />
  }

  if (type === 'inline') {
    return <HeaderInline navigation={navigation} />
  }
}
