import type { INavigationItem } from '@/types/navigation'
import FooterCheckout from './footer-checkout'
import FooterEnhanced from './footer-enhanced'

export default function Footer({
  type = 'enhanced',
  navigation,
}: {
  type: 'inline' | 'enhanced' | 'checkout' | 'checkout-contrast'
  navigation: INavigationItem[]
}) {
  if (type === 'enhanced') {
    return <FooterEnhanced navigation={navigation} />
  }

  if (type === 'checkout' || type === 'checkout-contrast') {
    return <FooterCheckout />
  }
}
